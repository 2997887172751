export default [
  {
    value: '0',
    reason: '질병 혹은 사고'
  },
  {
    value: '1',
    reason: '가족 문제 (행사, 경조사, 간병 등)'
  },
  {
    value: '2',
    reason: '출근 혹은 출장'
  },
  {
    value:  '3',
    reason: '여행'
  },
  {
    value: '4',
    reason: '기타'
  }
]