import React, { useEffect, useRef, useState } from 'react';
import { Td, Tr, Button, Heading, Text, Flex, Box } from '@chakra-ui/react';
import { dateToKoWithTime } from '../../handlingDate';
import { getContentInfoByKey } from '../../firestore/worshipContent';
import { DeleteIcon, LinkIcon, CalendarIcon, HamburgerIcon } from '@chakra-ui/icons';

const ApplyListRow = ({date, contentKey, reason, url_link, onClick, onDelete}) => {
  const mount = useRef(false)
  const [content, setContent] = useState('')
  const contentColor = ['orange.200', 'green.200', 'purple.200', 'cyan.700', 'gray.500', 'red.400', 'green.600']

  const handleClick = () => {
    let link = url_link
    let isLink = false

    if (link) {
      isLink = true;

      if (url_link.substring(0, 8) !== 'https://') {
        if (url_link.substring(0, 7) !== 'http://') 
          link = 'https://' + url_link  
        else 
          link = url_link.substring(7)
      }
      // window.open(link)
      window.location.href = link
    }
    onClick(isLink)
  }

  const handleDelete = () => {
    onDelete(date, contentKey)
  }

  const getContent = async (key) => {
    const contentInfo = await getContentInfoByKey(key)
    setContent(contentInfo.content)
  }

  useEffect(() => {
    if (!mount.current) {
      getContent(contentKey)
      mount.current = true
    }
  })

  return (
    <Flex w='100%' mb={4} boxShadow='md' >
      <Box 
        bg={contentColor[contentKey]} 
        w='3%' 
        borderLeftRadius='md'></Box>
      <Flex 
        flexDirection='column' 
        w='100%' 
        px={4} 
        pt={4} 
        pb={6} 
        borderRightRadius='md' 
        border='1px'
        borderLeft={0} 
        borderColor='gray.200'
        >
        <Flex justifyContent='space-between'>
          <Flex flexDirection='column'>
            <Text color='gray.500'>예배/모임</Text>
            <Heading as='h3' size='md' mb={0.5}>{content}</Heading>
            <Flex alignItems='center'>
              <CalendarIcon mr={1} color='gray.500'/>
              <Text color='gray.500'>{dateToKoWithTime(date.toDate())}</Text>
            </Flex>
          </Flex>
          <Button onClick={handleDelete} bg='none' p={0}>
            <DeleteIcon color='gray.600'/>
          </Button>
        </Flex>
        <Flex justifyContent='space-between' alignItems='flex-end' mt={2}>
          <Flex flexDirection='column' mt={3} mr={2} w='60%'>
            <Text color='gray.500'>사유</Text>
            <Heading as='h4' size='sm'>{reason}</Heading>
          </Flex>
          <Button 
            onClick={handleClick}
            p={4}
            bg='blue.500' 
            color='white' 
            _hover={{
              background: 'blue.600'
            }}
            >
              <Flex alignItems='center'>
                <LinkIcon mr={1}/>
                  <Text fontSize='sm' fontWeight='bold'>온라인 예배 바로가기</Text>
              </Flex>
          </Button>
          
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ApplyListRow;
