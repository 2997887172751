import React from 'react';
import { Field, Formik, Form } from 'formik';
import { FormErrorMessage, Image, Input, FormControl, VStack, Button, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../firestore/auth';

const Signup = () => {
  const navigate = useNavigate()

  const validatePhone = value => {
    let error

    if (!value) 
      error = '번호를 입력해주세요'
    else if (value.length < 11)
      error = '번호를 정확하게 입력해주세요'
    else if (value.length > 11)
      error = '"-" 없이 번호를 입력해주세요'
    
    return error  
  }

  const validateName = (value) => {
    let error
    if (!value) 
      error = '이름을 입력해주세요'
    
    return error
  }

  const handleSignup = (userName, userPhone, resetForm) => {
    signup(userPhone, userName)
    .then(() => {
      alert('회원가입 완료')
      navigate('/login')
    })
    .catch(error => {
      const errorCode = error.errorCode;
      const errorMessage = error.errorMessage;
      alert(errorMessage)
      console.log(errorCode)
      console.log(errorMessage)
      resetForm()
    })
  }

  return (
    <Flex flexDirection='column' p='20px' mt='56px'>
      <Heading as='h3' size='lg' mb='12px'>회원가입</Heading>
      <Formik
        initialValues={{
          name: '',
          phone: '',
        }}
        onSubmit={(values, {resetForm}) => {
          handleSignup(values.name, values.phone, resetForm)
        }}
      >
        {(props) => (
          <Form>
            <VStack spacing={1}>
              <Field name='name' validate={validateName}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.name && form.touched.name}>
                    <Input {...field} id='name' placeholder='이름을 입력해주세요' />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='phone' validate={validatePhone}>
                {({field, form})=> (
                  <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                    <Input {...field} id='phone' placeholder='"-" 없이 전화번호를 입력해주세요'/> 
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </VStack>
            <Button
            mt={4}
            bg='blue.500'
            color='white'
            _hover={{
              background: 'blue.600'
            }}
            isLoading={props.isSubmitting}
            type='submit'
            width='100%'
            >
              회원가입
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default Signup;  