import { 
  getAuth, 
  setPersistence, 
  browserSessionPersistence,
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  onAuthStateChanged,
  updatePassword } from "firebase/auth";

const auth = getAuth()

const signupEmail = (userPhone, password) => {
  const email = userPhone + '@hanaro.org'
  return createUserWithEmailAndPassword(auth, email, password)
};

const loginEmail = async (email, password) => {
  setPersistence(auth, browserSessionPersistence)
  .then(() => {
    return signInWithEmailAndPassword(auth, email, password)
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;

    console.log(errorCode)
    console.log(errorMessage)
    switch (errorCode) {
      case 'auth/wrong-password':
        alert('비밀번호가 틀렸어요')
        break
      case 'auth/user-not-found':
        alert('존재하지 않는 전화번호예요')
        break
      case 'auth/too-many-requests':
        alert('비밀번호를 너무 많이 틀렸어요\n잠시 뒤에 시도해주세요')
        break
      }
  })
};

const updateUserPassword = (newPassword) => {
  return updatePassword(auth.currentUser, newPassword)
}

export {signupEmail, loginEmail, auth, updateUserPassword}