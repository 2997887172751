import React, { useEffect, useRef } from 'react';
import { Field, Formik, Form } from 'formik';
import { FormErrorMessage, Image, Input, NumberInput, NumberInputField, FormControl, VStack, Button, Flex, Heading, Text, Highlight, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'
import { login, setAuthState } from '../../firestore/auth';

const LoginView = ({onAuthorized}) => {
  const navigate = useNavigate()
  const mount = useRef(false)

  useEffect(() => {
    if(!mount.current) {
      const sessionId = sessionStorage.getItem('session_id')
      if (sessionId) {
        setAuthState(sessionId)
        onAuthorized()
        navigate('/')
      }
      else {
        navigate('/login')
      }
      
      mount.current = true
    }  })

  const handleSignup = () => {
    navigate('/signup')
  }

  const validatePhone = value => {
    let error

    if (!value) 
      error = '번호를 입력해주세요'
    else if (value.length < 11)
      error = '번호를 정확하게 입력해주세요'
    else if (value.length > 11)
      error = '"-" 없이 번호를 입력해주세요'
    
    return error  
  }

  const handleLogin = (userPhone, resetForm) => {
    login(userPhone)
    .then(() => {
      setAuthState(userPhone)
      onAuthorized()
      navigate('/')
    })
    .catch((error) => {
      const errorCode = error.errorCode;
      const errorMessage = error.errorMessage;
      console.log(errorCode)
      console.log(errorMessage)
      alert(errorMessage)
      resetForm()
    });
  }

  return (
    <Flex flexDirection='column' p='20px' mt='56px'>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' mb='16px'>
        <Image src='/logo.jpeg' alt='hanaro logo' boxSize='100px' mb='24px'/>
        <Heading as='h3' size='lg' align='center'>온라인 예배/모임 신청</Heading>
      </Flex>

      <Flex justifyContent='center' alignItems='center' mb='16px' maxWidth='640px' mx='auto'>
        <Text align='center'>
          <Highlight
            query='Chrome'
            styles={{ fontWeight:'bold' }}
          >
            온라인 예배 신청 페이지는 Chrome 브라우저에 최적화 되어있습니다. 
            가급적 Chrome 브라우저를 이용해 주시기 바랍니다.
          </Highlight>
        </Text>
      </Flex>
      <Flex justifyContent='center' mb='24px'>
        <Button 
          color='white'
          bg='blue.500'
          _hover={{
            background: 'blue.600'
          }}
        >
          <Link href='https://apps.apple.com/kr/app/google-chrome/id535886823' isExternal>
            Chrome 다운로드
          </Link>
        </Button>
      </Flex>

      <Formik
        initialValues={{
          phone: '',
        }}
        onSubmit={(values, {resetForm}) => {

          handleLogin(values.phone, resetForm)
        }}
      >
        {(props) => (
          <Form>
            <VStack spacing={1}>
              <Field name='phone' validate={validatePhone}>
                {({field, form})=> (
                  <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                    {/* <NumberInput>
                      <NumberInputField {...field} id='phone' placeholder='"-" 없이 전화번호를 입력해주세요'/> 
                    </NumberInput> */}
                    <Input {...field} id='phone' inputMode='tel' placeholder='"-" 없이 전화번호를 입력해주세요'/>
                    {/* <Input {...field} id='phone'placeholder='"-" 없이 전화번호를 입력해주세요'/> */}
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </VStack>
            <Button
            mt={4}
            bg='blue.500'
            color='white'
            _hover={{
              background: 'blue.600'
            }}
            isLoading={props.isSubmitting}
            type='submit'
            width='100%'
            >
              로그인
            </Button>
          </Form>
        )}

      </Formik>
      <Button mt='8px' onClick={handleSignup}>회원가입</Button>
    </Flex>
  )
}

export default LoginView;