import React, { useState, useEffect, useRef } from 'react';
import { Heading, Text, Flex, Button } from '@chakra-ui/react';
import ApplyList from './components/apply/applyList';
import { useNavigate } from 'react-router-dom';
import { setAuthState } from './firestore/auth';

const Home = ({userPhone, onAuthorized}) => {
  const navigate = useNavigate()
  const mount = useRef(false)

  const handleApply = () => {
    navigate('/apply')
  }

  useEffect(() => {
    if(!mount.current) {
      const sessionId = sessionStorage.getItem('session_id')
      if (sessionId) {
        setAuthState(sessionId)
        onAuthorized()
        navigate('/')
      }
      else {
        navigate('/login')
      }
      
      mount.current = true
    }
  })
  

  return (
    <Flex flexDirection='column' p={5} pt={14}>
      <Heading size='md' mb={2}>온라인 예배/모임 신청</Heading>
      <Heading size='xl'>신청내역</Heading>
      {
        userPhone && <ApplyList userPhone={userPhone}/>
      }
      <Button 
        onClick={handleApply} 
        color='white'
        bg='blue.500'
        _hover={{
          background: 'blue.600'
        }}
        mt={6}
        >신청하기</Button>
    </Flex>
  )
}

export default Home;