import React, { useEffect, useState } from 'react';
import { 
  TableContainer, 
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableCaption,
  Text,
  Flex
} from '@chakra-ui/react';
import ApplyListRow from './applyListRow';
import { useRef } from 'react';
import { getWorshipLink } from '../../firestore/worship';
import { getApplyList } from '../../firestore/apply';
import { deleteApply } from '../../firestore/apply';
import CheckLinkModal from '../modals/checkLinkModal';
import ValidateDeleteApplyModal from '../modals/validateDeleteApplyModal';
import SuccessDeleteApplyModal from '../modals/successDeleteApplyModal';

const ApplyList = ({userPhone}) => {
  const mount = useRef(false)
  const [applyList, setApplyList] = useState([])
  const [isClicked, setIsClicked] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [deletedApply, setDeletedApply] = useState(null)

  const getApplyData = async (phone) => {
    const arr = await getApplyList(phone)
    setApplyList(arr)
  }

  useEffect(() => {
    if (!mount.current) {
      getApplyData(userPhone)
      mount.current = true
    }
  })

  useEffect(() => {
    if (!isSuccess)
      getApplyData(userPhone)
  }, [isSuccess])

  const handleClick = async (isLink) => {
    if (!isLink) {
      setIsClicked(true)
    }
  }

  const handleAfterOpen = () => {
    setIsClicked(false)
    setIsDelete(false)
  }

  const handleClickDelete = (date, contentKey) => {
    setDeletedApply({date: date, contentKey: contentKey})
    setIsDelete(true)
  }

  const handleDelete = async () => {
    const {date, contentKey} = deletedApply
    await deleteApply(userPhone, date, contentKey)
    setIsSuccess(true)
  }

  const handleComplete = () => {
    setIsSuccess(false)
  }

  return (
    <>
      <CheckLinkModal isClicked={isClicked} onAfterOpen={handleAfterOpen}/>
      <ValidateDeleteApplyModal isModalOpen={isDelete} onAfterOpen={handleAfterOpen} onDelete={handleDelete}/>
      <SuccessDeleteApplyModal isSuccess={isSuccess} onComplete={handleComplete}/>
        {
          applyList.length
          ?
          <Flex flexDirection='column' mt={4} w='100%'>
          {
            applyList.map(l => {
              return <ApplyListRow key={l.key} date={l.date} contentKey={l.contentKey} reason={l.reason} url_link={l.url_link} onClick={handleClick} onDelete={handleClickDelete}/>
            }) 
          }
          </Flex>
          :
          <Text>신청내역이 없습니다.</Text>
        }
    </>
  )
}

export default ApplyList;