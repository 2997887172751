import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SuccessApplyModal = ({isSuccess}) => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isSuccess) {
      onOpen()
    }
  })

  const handleComplete = () => {
    onClose()
    navigate('/')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>온라인 예배/모임 신청 완료</ModalHeader>
      <ModalBody>
        <Text>신청이 완료되었습니다.</Text>
      </ModalBody>
      <ModalFooter>
        <Button 
          bg='blue.500'
          color='white'
          _hover={{
            background: 'blue.600'
          }}
          onClick={handleComplete}>
          확인
        </Button>
      </ModalFooter>
    </ModalContent>
    </Modal>
  )
}

export default SuccessApplyModal;