import { deleteDoc, getDoc, getFirestore, setDoc, doc, getDocs, query, collection } from "firebase/firestore";
import firebaseApp from "../firebase/firebase";

const db = getFirestore(firebaseApp)

const getUserIdIndex = async () => {
  const ref = await getDoc(doc(db, 'identifier', 'user'))

  if(ref.exists())
    return ref.data().id
  else
    return -1
}

const setUserIdIndex = async () => {
  const idx = await getUserIdIndex()
  let ref = null

  if (idx >= 0) {
    ref = await setDoc(doc(db, 'identifier', 'user'), {
      id: idx+1
    })
  }
  
  return ref
}

const createUser = async (userPhone, name) => {
  let userIdIndex = await getUserIdIndex()
  let userRef = null

  if(userIdIndex >= 0) {
    try {
      userRef = await setDoc(doc(db, 'user', userPhone), {
        id: userIdIndex + 1,
        name: name,
        phone: userPhone,
        isThedream: false,
        isDisciple: false
      })
      await setUserIdIndex()
    }
    catch(err) {
      throw(err)
    }
  }

  return userRef
}

const isCreatedUser = async (userPhone) => {
  const userRef = await getDoc(doc(db, 'user', userPhone))

  if (userRef.exists())
    return true
  else
    return false
}

const getUserName = async (userPhone) => {
  const userRef = await getDoc(doc(db, 'user', userPhone))

  if (userRef.exists())
    return userRef.data().name
  else
    return null
}

const getUserInfo = async (userPhone) => {
  const userRef = await getDoc(doc(db, 'user', userPhone))

  if (userRef.exists())
    return userRef.data()
}

export {
  createUser,
  isCreatedUser,
  getUserName,
  getUserInfo
}