import firebaseApp from "../firebase/firebase";
import { getFirestore, getDoc, doc } from "firebase/firestore";

const db = getFirestore(firebaseApp)

const getContentInfoByKey = async contentKey => {
  contentKey *= 1
  const wcRef = await getDoc(doc(db, 'worshipContent', 'contentList'))
  const worshipContentList = wcRef.data().content

  if (worshipContentList.length > contentKey)
    return worshipContentList[contentKey]
  else return null
}

const getContentInfoList = async () => {
  const wcRef = await getDoc(doc(db, 'worshipContent', 'contentList'))
  return wcRef.data().content
}

export {
  getContentInfoByKey,
  getContentInfoList
}