import { deleteField, doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import firebaseApp from "../firebase/firebase";
import { dateToYYYYMMDD, setTimeToDate } from "../handlingDate";
import { getContentInfoByKey } from "./worshipContent";

const db = getFirestore(firebaseApp)

const createWorship = async (date, contentKey) => {
  const contentInfo = await getContentInfoByKey(contentKey)
  const {content, time} = contentInfo
  const docName = `${dateToYYYYMMDD(date)}_${content}`

  const worshipRef = doc(db, 'worship', docName)
  await setDoc(worshipRef, {
    date: setTimeToDate(date, time),
    contentKey: contentKey
  })
  
  return worshipRef
}

const getWorshipRef = async (date, contentKey) => {
  const contentInfo = await getContentInfoByKey(contentKey)
  const content = contentInfo.content
  const docName = `${dateToYYYYMMDD(date.toDate())}_${content}`

  const worshipRef = doc(db, 'worship', docName)
  return worshipRef
}

const getWorshipLink = async (date, contentKey) => {
  const worshipRef = await getWorshipRef(date, contentKey)
  const worshipDocSnap = await getDoc(worshipRef)

  if (worshipDocSnap.exists() && worshipDocSnap.data().link)
    return worshipDocSnap.data().link
  else return null
}

const createWorshipLink = async (date, contentKey, link) => {
  const worshipRef = getWorshipRef(date, contentKey)
  await updateDoc(worshipRef, {
    link: link
  })
  return worshipRef
}

const deleteWorshipLink = async (date, contentKey) => {
  const worshipRef = getWorshipRef(date, contentKey)
  await updateDoc(worshipRef, {
    link: deleteField()
  })
}

export {
  createWorship,
  createWorshipLink,
  deleteWorshipLink,
  getWorshipLink
}