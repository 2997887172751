import { collection, getDocs, getFirestore, updateDoc, where, doc, addDoc, query, deleteDoc } from "firebase/firestore";
import firebaseApp from "../firebase/firebase";
import { getContentInfoByKey } from "./worshipContent";
import { getWorshipLink } from './worship';
import { setTimeToDate, checkTimeCondition } from "../handlingDate";
import { getUserInfo } from "./user";
const db = getFirestore(firebaseApp)

function timeConditionError() {
  this.errorCode = 'apply/passed_worship'
  this.errorMessage = '이미 종료된 예배입니다.'
}

function applyDuplicationError() {
  this.errorCode = 'apply/duplication'
  this.errorMessage = '이미 신청한 예배입니다.'
}

function worshipAccessError() {
  this.errorCode = 'apply/access_denied'
  this.errorMessage = '신청할 수 없는 모임입니다.'
}

const createApply = async (userPhone, date, contentKey, reason) => {
  const contentInfo = await getContentInfoByKey(contentKey)
  const time = contentInfo.time
  const contentDate = setTimeToDate(date, time)
  const user = await getUserInfo(userPhone)
  if ((contentKey === 1 && !user.isThedream) || (contentKey === 6 && !user.isDisciple)) {
    throw new worshipAccessError()
  }

  const apply = await getApply(userPhone, contentDate, contentKey)

  if (apply != null) {
    throw new applyDuplicationError()
  }
  
  const logDate = new Date()
  const isSatisfy = contentKey === 1 || contentKey === 6 ? true : checkTimeCondition(logDate, contentDate) //더드림, 제자학교는 제외 (불규칙이라서)
  // if (isTheDream || isSatisfy) {
    if (isSatisfy) {
    const userRef = doc(db, 'user', userPhone)
    const applyRef = await addDoc(collection(db, 'apply'), {
      user: userRef,
      date: contentDate,
      logDate: logDate,
      contentKey: contentKey,
      reason: reason,
      isApproved: true
    })
    return applyRef
  } else {
    throw new timeConditionError()
  }

}

const getApply = async (userPhone, date, contentKey) => {
  const userRef = doc(db, 'user', userPhone)
  const q = query(
    collection(db, 'apply'),
    where('user', '==', userRef),
    where('date', '==', date),
    where('contentKey', '==', contentKey)
  )
  
  const qsnap = await getDocs(q)
  const result = qsnap.docs.map(d => {
    return { key: d.id, ...d.data()}
  })

  if (result[0]) {
    const contentInfo = await getContentInfoByKey(contentKey)
    return {
      ...result[0],
      ...{content: contentInfo.content}
    }
  }
  else
    return null
}

const getApplyList = async (userPhone) => {
  const userRef = doc(db, 'user', userPhone)
  const today =  new Date(new Date().toDateString())
  const q = query(
    collection(db, 'apply'),
    where('user', '==', userRef),
    where('date', '>=', today)
  )
  
  const qsnap = await getDocs(q)
  const result = qsnap.docs.map(d => {
    return {key: d.id, ...d.data()}
  })

  if (result) {
    const items = await Promise.all(
      result.map(async i => {
        const link = await getWorshipLink(i.date, i.contentKey)
        return {url_link : link, ...i}
      })
    )

    return items
  } else return null
}

const updateApplyApproved = async (userPhone, date, contentKey, isApproved) => {
  const applyRef = await getApply(userPhone, date, contentKey)
  if (applyRef) {
    updateDoc(applyRef, {
      isApproved: isApproved
    })
    return applyRef
  } else return null
}

const deleteApply = async (userPhone, date, contentKey) => {
  const userRef = doc(db, 'user', userPhone)
  const q = query(
    collection(db, 'apply'),
    where('user', '==', userRef),
    where('date', '==', date),
    where('contentKey', '==', contentKey)
  )

  const qsnap = await getDocs(q)
  Promise.all(qsnap.docs.map(async d => {
    await deleteDoc(d.ref)
  }))
}

export {
  createApply,
  getApply,
  getApplyList,
  updateApplyApproved,
  deleteApply
}