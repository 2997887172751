import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Heading,
  Text
} from '@chakra-ui/react'

const ValidateDeleteApplyModal = ({isModalOpen, onAfterOpen, onDelete}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isModalOpen) {
      onOpen()
      onAfterOpen()
    }
  })

  const handleClose = () => {
    onClose()
  }

  const handleDelete = () => {
    onClose()
    onDelete()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>신청 삭제</ModalHeader>
        <ModalBody>
          <Text fontWeight='bold' fontSize='lg'>정말 삭제하시겠어요?</Text>
        </ModalBody>
        <ModalFooter>
          <Button 
            bg='red.600'
            color='white'
            _hover={{
              background: 'red.400'
            }}
            mr={2} 
            onClick={handleClose}
            >취소</Button>
          <Button onClick={handleDelete}>
            삭제
          </Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  )

}

export default ValidateDeleteApplyModal;