import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import firebaseApp from './firebase/firebase'
import Signup from './components/loginView/signup';
import LoginView from './components/loginView/loginView';
import { auth } from './firebase/authService';
import Home from './home';
import Apply from './components/apply/apply';
import { authState } from './firestore/auth';

function App() {
  const [currentUserPhone, setCurrentUserPhone] = React.useState(null)

  const handleAuthorized = () => {
    if (authState.currentUser) {
      setCurrentUserPhone(authState.currentUser)
    }
  }

  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <Home userPhone={currentUserPhone} onAuthorized={handleAuthorized}/>
          }></Route>
          <Route path="/login" element={
            <LoginView onAuthorized={handleAuthorized}/>
          }></Route>
          <Route path='/signup' element={
            <Signup/>
          }>
          </Route>
          <Route path='/apply' element={
            <Apply userPhone={currentUserPhone}/>
          }>
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
