import React, { useEffect, useState, useRef } from 'react';
import { Field, Formik, Form } from 'formik';
import { Flex, Text, Heading, Button, Input, FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  RadioGroup, Radio,
  Stack, Divider} from '@chakra-ui/react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { ko } from 'date-fns/esm/locale'
import ValidateApplyModal from '../modals/validateApplyModal';
import SuccessApplyModal from '../modals/successApplyModal';
import FailApplyModal from '../modals/failApplyModal';
import { createApply } from '../../firestore/apply';
import { getContentInfoByKey } from '../../firestore/worshipContent';
import { useNavigate } from 'react-router-dom';
import reasons from '../../reasons';
import { getContentInfoList } from '../../firestore/worshipContent';

const Apply = ({userPhone}) => {
  const [input, setInput] = useState('')
  const [contentInfoList, setContentInfoList] = useState([])
  const [contentKey, setContentKey] = useState(null)
  const [reasonValue, setReasonValue] = useState('0')
  const [startDate, setStartDate] = useState(new Date())
  const [applyValues, setApplyValues] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFail, setIsFail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isEtcInValid, setIsEtcInValid] = useState(false)
  const navigate = useNavigate()
  const mount = useRef(false)

  const getContentInfoListDidMount = async () => {
    const clist = await getContentInfoList()
    setContentInfoList(clist)
  }

  const getDefaultContentKeyByDay = (date) => {
    for (let i = 0; i < contentInfoList.length; i++) {
      if (date.getDay() === contentInfoList[i].day) {
        return i
      }
    }
  }

  const getNextCloseDay = (date) => {
    const day = date.getDay()
    let addDay = 0
    if ([1, 2].includes(day)){
      addDay = day
    } else if([4].includes(day)) {
      addDay = 1
    }
    date.setDate(date.getDate() + addDay)
    return date
  }

  useEffect(() => {
    if (!mount.current) {
      const cday = getNextCloseDay(new Date())
      setStartDate(cday)

      getContentInfoListDidMount()
      mount.current = true
    }
  })

  useEffect(() => {
    const cday = getNextCloseDay(startDate)
    const dc = getDefaultContentKeyByDay(cday)
    setContentKey(dc)
  }, [startDate, contentInfoList.length])



  const handleInputChange = e => setInput(e.target.value)

  const handleValidate = () => {
    let reason = reasons[reasonValue].reason;
    if (reasonValue === reasons[reasons.length-1].value) 
      reason += ': ' + input

    createApply(userPhone, startDate, contentKey*1, reason)
    .then(() => {
      setIsSuccess(true)
    })
    .catch(error => {
      const errorCode = error.errorCode
      const errorMessage = error.errorMessage

      setIsFail(true)
      setErrorMessage(errorMessage)
      console.log(errorCode)
      console.log(errorMessage)
    })
  }

  const handleSubmit = async () => {
    const contentInfo = await getContentInfoByKey(contentKey)
    const content = contentInfo.content
    let reason = reasons[reasonValue].reason
    if (reasonValue === '4')
      reason += ` : ${input}`
    const values = {
      date: startDate,
      content: content,
      reason: reason
    }
    setApplyValues(values)
    setIsSubmit(true)
  }

  const handleCancel = () => {
    navigate('/')
  }

  const handleAfterOpen = () => {
    setIsSubmit(false)
    setIsFail(false)
  }

  const filterDate = date => {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const day = date.getDay()

    return [0, 3, 5, 6].includes(day) && date >= today
  }

  const handleOutFocusEtc = () => {
    setIsEtcInValid(false)
  }

  return (
    <Flex flexDirection='column' p={5} pt={14}>
      <ValidateApplyModal isSubmit={isSubmit} values={applyValues} onValidate={handleValidate} onAfterOpen={handleAfterOpen}/>
      <SuccessApplyModal isSuccess={isSuccess}/>
      <FailApplyModal isFail={isFail} errorMessage={errorMessage} onAfterOpen={handleAfterOpen}/>
      <Heading size='xl' mb={2}>신청하기</Heading>
      <Divider mb={4}/>
      
      <FormLabel fontSize='lg'>날짜와 예배/모임을 선택해주세요</FormLabel>
      <DatePicker
        selected={startDate}
        locale={ko}
        dateFormat="yyyy년 MM월 dd일"
        placeholderText='날짜를 선택해주세요'
        isClearable
        inline
        filterDate={filterDate}
        onChange={date => {
          setStartDate(date)
        }}
      />
      
      <Formik
        initialValues={{
          contentKey: contentKey,
          reasonValue: '0'
        }}
        onSubmit={() => {
          if (reasonValue === reasons[reasons.length-1].value && !input)
            setIsEtcInValid(true)
          else
            handleSubmit()
        }}  
      >
      {() => (
        <Form>
          <Field name='contentKey'>
            {() => (
                <FormControl h={20} mt={2}>
                <RadioGroup onChange={setContentKey} value={`${contentKey}`}>
                  <Stack>
                  {
                    contentInfoList.map((c, i) => {
                      if (![7, 8, 9, 10, 11].includes(i) && c.day == startDate.getDay()) {
                        return <Radio key={c.content} value={`${i}`}>{c.content}</Radio>
                      }
                    })
                  }
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </Field>

          <Field name='reasonValue'>
            {() => (
              <FormControl>
                <FormLabel fontSize='lg' mt={8}>사유를 입력해주세요</FormLabel>
                <RadioGroup onChange={setReasonValue} value={reasonValue}>
                  <Stack>
                    {
                      reasons.map(r => {
                        if (r.value == `${reasons.length-1}`)
                          return (
                            <Flex>
                              <Radio key={r.value} value={r.value} mr={2} w='20%'>
                                <Text>{r.reason}</Text>
                              </Radio>
                              <FormControl isInvalid={isEtcInValid}>
                                <Input 
                                  onChange={handleInputChange} 
                                  value={input} 
                                  placeholder='기타 사유를 입력해주세요'
                                  variant='flushed'
                                  h={6}
                                  isDisabled={reasonValue !==`${reasons.length-1}`}
                                  onFocus={handleOutFocusEtc}
                                  />
                                  {
                                    isEtcInValid 
                                    ? <FormErrorMessage>사유는 필수예요.</FormErrorMessage>
                                    : <></>
                                  }
                              </FormControl>
                            </Flex>
                            )
                        else
                          return <Radio key={r.value} value={r.value} onChange={handleOutFocusEtc}>{r.reason}</Radio>
                      })
                    }
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </Field>
          <Flex mt={6} justifyContent='space-around'>
            <Button w='100%' mr={4} onClick={handleCancel}>취소</Button>
            <Button 
              type='submit'
              w='100%'
              bg='blue.500'
              color='white'
              _hover={{
                background: 'blue.600'
              }}
              >
              신청완료
            </Button>
          </Flex>
        </Form>
      )}
      </Formik>        
    </Flex>
  );
};

export default Apply;