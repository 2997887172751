import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Link,
  Text
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react';

const CheckLinkModal = ({isClicked, onAfterOpen}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  useEffect(() => {
    if (isClicked) {
      onOpen()
      onAfterOpen()
    }
  })

  const handleComplete = () => {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>온라인 예배</ModalHeader>
      <ModalBody>
        <Text>아직 시작 전이에요</Text>
      </ModalBody>
      <ModalFooter>
        <Button 
          bg='blue.500' 
          color='white' 
          _hover={{
            background: 'blue.600'
          }}
          onClick={handleComplete}>
          확인
        </Button>
      </ModalFooter>
    </ModalContent>
    </Modal>
  )
}

export default CheckLinkModal;