import React, {useEffect} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Heading,
  Text,
  Badge,
  Flex,
  VStack
} from '@chakra-ui/react'
import { dateToKo } from '../../handlingDate';

const ValidateApplyModal = ({isSubmit, values, onValidate, onAfterOpen}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isSubmit) {
      onOpen()
      onAfterOpen()
    }
  })

  const handleValidate = () => {
    onClose()
    onValidate()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>신청 확인</ModalHeader>
        <ModalBody>
          <Heading as='h3' size='md' mb={2}>정말 이렇게 신청하시겠어요?</Heading>
          <VStack spacing={2} align='stretch' w='80%' ml={4}>
            <Flex justifyContent='space-between' alignItems='center'>
              <Badge colorScheme='blue' variant='subtle'>날짜</Badge>
              <Text fontWeight='bold'>{values && dateToKo(values.date)}</Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems='center'>
              <Badge colorScheme='blue' variant='subtle'>예배 / 모임</Badge>
              <Text fontWeight='bold'>{values && values.content}</Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems='center'>
              <Badge colorScheme='blue' variant='subtle'>사유</Badge>
              <Text fontWeight='bold'>{values && values.reason}</Text>
            </Flex>
          </VStack>
        </ModalBody>
        <ModalFooter>
        <Button mr={3} onClick={handleClose}>
          취소
        </Button>
          <Button 
            bg='blue.500'
            color='white'
            _hover={{
              background: 'blue.600'
            }}
            mr={2} 
            onClick={handleValidate}>신청</Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  )


}

export default ValidateApplyModal;