const dateToKo = dateObj => {
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  
  return `${year}년 ${month >= 10 ? month : '0' + month} 월 ${date >= 10 ? date : '0' + date}일`
}

const dateToYYYYMMDD = dateObj => {
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  
  return `${year}${month >= 10 ? month : '0' + month}${date >= 10 ? date : '0' + date}`
}

const dateToMMDD = dateObj => {
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  
  return `${month >= 10 ? month : '0' + month}월 ${date >= 10 ? date : '0' + date}일`
}

const setTimeToDate = (dateObj, timeString) => {
  const [hour,minute] = timeString.split(':').map(s => parseInt(s, 10))
  dateObj.setHours(hour,minute, 0, 0)
  return dateObj
}

const dateToKoWithTime = dateObj => {
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()
  const seconds = dateObj.getSeconds()
  
  return `${year}년 
    ${month >= 10 ? month : '0' + month}월 
    ${date >= 10 ? date : '0' + date}일
    ${hours >= 10 ? hours : '0' + hours}시 
    ${minutes >= 10 ? minutes : '0' + minutes}분`
}

const checkTimeCondition = (curDate, contentDate) => {
  const timeInterval = curDate - contentDate
  const hourTime = 60 * 60 * 1000

  if (timeInterval > hourTime *  2) return false
  else return true
}


export {
  dateToKo,
  dateToYYYYMMDD,
  setTimeToDate,
  dateToKoWithTime,
  dateToMMDD,
  checkTimeCondition
}